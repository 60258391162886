<template>
    <div>
        <MXPageLayout>
            <div slot="filter">
                <Row>
                    <Form style="display:flex;justify-content:end" ref="formInline" :model="fromData" inline :label-width="10" label-position="right">
                        <FormItem>
                            <Select v-model="fromData.villageId" placeholder="全部小区" clearable style="width:180px">
                                <Option v-for="(item, index) in villageFormItemSelectList" :key="index" :value="item.v_village_id">
                                    {{ item.v_village_name }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem>
                            <DatePicker @on-change="fromData.startTime = $event" :value="fromData.startTime" format="yyyy-MM-dd" type="date" placeholder="开始时间" style="width: 180px">
                            </DatePicker>
                        </FormItem>
                        <FormItem>
                            <DatePicker @on-change="onChangeEndTime" :value="fromData.endTime" format="yyyy-MM-dd" type="date" placeholder="结束时间" style="width: 180px">
                            </DatePicker>
                        </FormItem>
                        <FormItem>
                            <Select v-model="fromData.orderType" placeholder="展示方式" style="width:140px">
                                <Option :value="item.value" v-for="(item, index) in DisplayTypeDataList" :key="index">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                        <Button type="primary" shape="circle" icon="ios-search" @click="searchList()">搜索
                        </Button>
                    </Form>
                </Row>
            </div>
            <div slot="title">
                {{pageTitle}}
            </div>
            <div slot="content">
                <div class="echart-box" id="E_zkcl" :style="
          `width:100%;height:600px;zoom:${zoom};transform:scale(${1 /
            zoom});transform-origin:0 0`"></div>
            </div>
        </MXPageLayout>
    </div>
</template>
<script>
import { getElevatorRunDataStatistics } from '@/api/ststistics/index.js'
import { searchVillageSelectList } from '@/api/home/index.js'
import Statistics from '@/pages/Statistics/statistics'
import * as echarts from 'echarts'
export default {
    data() {
        return {

            cityList: [{
                    value: 'New York',
                    label: 'New York'
                },
                {
                    value: 'London',
                    label: 'London'
                },
                {
                    value: 'Sydney',
                    label: 'Sydney'
                },
                {
                    value: 'Ottawa',
                    label: 'Ottawa'
                },
                {
                    value: 'Paris',
                    label: 'Paris'
                },
                {
                    value: 'Canberra',
                    label: 'Canberra'
                }
            ],
            model1: '',
            fromData: {
                startTime: this.formatDate(-30),
                endTime: this.formatDate(0),
                villageId: '',
                orderType: 'asc', //desc降序 asc升
                orderBy: 'total',
            },
            option: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    // orient: 'vertical', //垂直显示
                    // y: 'center', //延Y轴居中
                    // x: 'right',
                    data: ['运行次数', '开关门次数', '弯折次数'],
                    selectedMode: 'single',
                    textStyle: {
                        color: '#fff',
                        fontSize: 16,
                        fontWeight: 'normal'
                    },
                    selected: {
                        运行次数: true,
                        开关门次数: false,
                        弯折次数: false
                    },
                },
                grid: {
                    left: '3%',
                    right: '10%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    axisLabel: {
                        interval: 0,
                        rotate: 18
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            fontSize: 16,
                            color: '#fff'
                        }
                    }
                },
                series: [{
                        name: 'Direct',
                        type: 'bar',
                        stack: 'total',
                        data: [320, 302, 301, 334, 390, 330, 320]
                    },
                    {
                        name: 'Mail Ad',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [120, 132, 101, 134, 90, 230, 210]
                    },
                    {
                        name: 'Affiliate Ad',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [220, 182, 191, 234, 290, 330, 310]
                    },
                    {
                        name: 'Video Ad',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [150, 212, 201, 154, 190, 330, 410]
                    },
                    {
                        name: 'Search Engine',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [820, 832, 901, 934, 1290, 1330, 1320]
                    }
                ]
            },
            zoom: 1,
            villageFormItemSelectList: [],
            // 展示方式
            DisplayTypeDataList: [{
                    value: 'asc',
                    label: '升序'
                },
                {
                    value: 'desc',
                    label: '降序'
                }
            ],
            selectedLegend: '运行次数'
        }
    },
    mounted() {
        this.reqVillageList()
        // this.searchList()
    },
    mixins: [Statistics],
    computed: {
        theme() {
            return this.$store.getters.theme
        },
        user() {
            return this.$store.state.user
        }
    },

    watch: {
		'fromData.orderBy'(newVal, oldVal) {
		    if (newVal !== oldVal) {
		        this.searchList()
		    }
		},
		 
        theme(newVal, oldVal) {
            if (newVal != oldVal) {
                this.searchList()
            }
        }
    },

    methods: {
        searchList() {
            let params = this.formatTimeParams('startTime', 'endTime', this.fromData)
            getElevatorRunDataStatistics(params).then(res => {
                let myChart = this.$echarts.init(document.getElementById('E_zkcl'))
				this.chart = myChart
                res.info.series.splice(-2)

                let showBar;
                let categories;
                let _data = [];
                let axisData;


                showBar = res.info.series.find(item => item.name == this.selectedLegend)
                showBar.data.forEach((item, index) => {
                   let obj = {} 
                   obj['name'] = res.info.categories[index]
                   obj['value'] = item 
                   _data.push(obj)
                })

                let dataList;
                if(this.fromData.orderType == 'asc') {
                    dataList = _data.sort(function(a, b){return a['value'] - b['value'] })
                } else {
                    dataList = _data.sort(function(a, b){return b['value'] - a['value'] })
                }

                categories = dataList.map(d => {return d.name })
                axisData = dataList.map(d => {return d.value })

                if (res.info) {
                    this.option.xAxis.data = categories.map(item => {
                        return {
                            value: item,
                            textStyle: {
                                color: this.theme == 'dark' ? '#fff' : '',
                                fontSize: 12
                            }
                        }
                    })
                    

                    res.info.series.forEach(item => {
                        if (item.type == 'column') {
                            item.type = 'bar'
                            item.barWidth = this.option.xAxis.data > 10 ? 'auto' : 30

                            item.color = new echarts.graphic.LinearGradient(0, 0, 1, 1, [{
                                    offset: 0,
                                    color: '#0ff'
                                },
                                {
                                    offset: 1,
                                    color: '#00aff8'
                                }
                            ])

                            item.data = axisData
                        }
                    })
                    this.option.series = res.info.series.filter(item => {
                        return item.name != '运行距离' && item.name != '平层次数'
                    })
                    this.option.yAxis.axisLabel.color = this.theme == 'dark' ? '#fff' : ''
                }

                console.log(res)
                myChart.setOption(this.option)

                let that = this;
                myChart.on('legendselectchanged', function(params) {
                    if (params.name == '运行次数') {
                        that.option.legend.selected['运行次数'] = true
                        that.option.legend.selected['开关门次数'] = false
                        that.option.legend.selected['弯折次数'] = false
                        that.selectedLegend = '运行次数'
						that.fromData.orderBy = 'runCount'
                    }
                   if (params.name == '开关门次数') {
                        that.option.legend.selected['运行次数'] = false
                        that.option.legend.selected['开关门次数'] = true
                        that.option.legend.selected['弯折次数'] = false
                        that.selectedLegend = '开关门次数'
						that.fromData.orderBy = 'doorCount'
                    }
                   if (params.name == '弯折次数') {
                        that.option.legend.selected['运行次数'] = false
                        that.option.legend.selected['开关门次数'] = false
                        that.option.legend.selected['弯折次数'] = true
                        that.selectedLegend = '弯折次数'
						that.fromData.orderBy = 'bendCount'
                    }
                    myChart.hideLoading()
                    myChart.setOption(that.option)
                })
            })
        },
        reqVillageList() {
            searchVillageSelectList(this.buildParams({})).then(res => {
                this.villageFormItemSelectList = res.info

                if(this.villageFormItemSelectList.length > 0) {
                    this.fromData.villageId = this.villageFormItemSelectList[0]['v_village_id']
                }

               this.searchList() 
            })
        },
        getModuleName() {
            return '运行数据'
        },
        buildParams(params) {
            params.vProjectId = this.user.user.v_project_id
            return params
        },
        onChangeEndTime(e) {
            this.fromData.endTime = e + ' 23:59:59'
        }
    }
}
</script>
<style scoped>
.home {
    padding: 10px 30px 0 10px;
}

::v-deep .ivu-select-selected-value {
    color: #fff !important;
}
</style>